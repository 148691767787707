import { Container } from "./Container";

const faqs = [
  [
    {
      question: "What is BotPanel?",
      answer: "BotPanel is a Discord Bot Dashboard and Website builder that allows you to create a customizable dashboard and homepage for your Discord Bot.",
    },
    {
      question: "Is BotPanel free?",
      answer: "Yes, BotPanel is free to use. You can use the free plan to get started and upgrade to a paid plan if you need more features. Free user's can have up to 10 servers handled per bot.",
    },
    {
      question: "Can I use a custom domain?",
      answer: "Yes, you can use a custom domain for your BotPanel. You can also use a subdomain of botpanel.gg for free.",
    },
    {
      question: "Do I need to know any coding or programming to use BotPanel?",
      answer: "No, you do not need to know any coding or programming to use BotPanel. You can use the free plan to get started and upgrade to a paid plan if you need more features.",
    },
  ],
  [
    {
      question: "What is the pricing?",
      answer: "BotPanel is free to use. You can use the free plan to get started and upgrade to a paid plan if you need more features. Free user's can have up to 10 servers handled per bot.",
    },
    {
      question: "When will BotPanel come out of beta?",
      answer: "We are currently working on getting BotPanel out of beta. We are currently in the testing phase and are working on fixing any bugs and adding any new features that we want to add.",
    },
    {
      question: "Do I need to use BotGhost to use BotPanel?",
      answer: "Currently yes. BotPanel is built to work with BotGhost, but we are working on making it work with other bots as well.",
    },
    {
      question: "Are all the listed features available right now?",
      answer: "No, not all the features are available right now. We are working on adding more features and fixing any bugs that we find.",
    },
  ],
  [
    {
      question: "Do I need BotGhost premium to use BotPanel?",
      answer: "No, you do not need BotGhost premium to use BotPanel. However, BotGhost premium features are still locked behind the paywall for your users.",
    },
    {
      question: "Can I disable the BotPanel branding on my dashboard?",
      answer: "Yes, you can disable the BotPanel branding on your dashboard. A paid plan is required to do this.",
    },
    {
      question: "How do I contact support?",
      answer: "You can contact support by joining our Discord server at discord.gg/botghost.",
    },
    {
      question: "What is the platform fee for the monetization feature?",
      answer: "The platform fee for the monetization feature is currently 0%. This may change in the future as we add more features to BotPanel.",
    },
  ],
];

export function Faqs() {
  return (
    <section id="faq" aria-labelledby="faq-title" className="relative overflow-hidden  py-20 sm:py-32">
      <img className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]" src={""} alt="" width={1558} height={946} unoptimized />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 id="faq-title" className="font-bold text-4xl tracking-tight text-white sm:text-5xl">
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-400">Frequently asked questions about BotPanel.</p>
        </div>
        <ul role="list" className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-white">{faq.question}</h3>
                    <p className="mt-4 text-sm text-slate-400">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
