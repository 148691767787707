"use client";

// import Link from "next/link";
import { Popover, PopoverButton, PopoverBackdrop, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";

import { Button } from "./Button";
import { Container } from "./Container";

import { NavLink } from "./NavLink";

function MobileNavLink({ href, children }) {
  return (
    <PopoverButton as={<a>/</a>} href={href} className="block w-full p-2">
      {children}
    </PopoverButton>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg aria-hidden="true" className="h-3.5 w-3.5 overflow-visible stroke-slate-700" fill="none" strokeWidth={2} strokeLinecap="round">
      <path d="M0 1H14M0 7H14M0 13H14" className={clsx("origin-center transition", open && "scale-90 opacity-0")} />
      <path d="M2 2L12 12M12 2L2 12" className={clsx("origin-center transition", !open && "scale-90 opacity-0")} />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none" aria-label="Toggle Navigation">
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <PopoverBackdrop transition className="fixed inset-0 bg-slate-900/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in" />
      <PopoverPanel transition className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-main-bg p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in">
        <PopoverButton>
          <NavLink href="#features">Features</NavLink>
        </PopoverButton>
        <PopoverButton>
          <NavLink href="#faq">FAQs</NavLink>
        </PopoverButton>
        <PopoverButton>
          <NavLink href="#pricing">Pricing</NavLink>
        </PopoverButton>
        <hr className="m-2 border-slate-300/40" />
        <PopoverButton>
          <NavLink href="https://dashboard.botpanel.gg">Sign in</NavLink>
        </PopoverButton>
      </PopoverPanel>
    </Popover>
  );
}

export function Header() {
  return (
    <header className="py-10 text-white">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <a href="#" className="font-semibold text-xl flex flex-row items-center gap-x-3" aria-label="Home">
              {/* <Logo className="h-10 w-auto" /> */}
              <img className="h-9" src={process.env.PUBLIC_URL + "/img/logo.png"}></img>
              <span>BotPanel</span>
            </a>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#faq">FAQs</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden md:block">
              <NavLink href="https://dashboard.botpanel.gg">Sign in</NavLink>
            </div>
            <Button color="bg-base-blue " href="https://dashboard.botpanel.gg" className={"bg-base-blue"}>
              <span>Get Started</span>
            </Button>
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
